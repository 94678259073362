import React, { useState } from 'react'
import _get from 'lodash/get'

import Page from '../components/Page'
import SEO from '../components/SEO'
import PriceCard from '../components/primary/PriceCard'
import Form from '../components/forms/partials/NetlifyForm'
import Submit from '../components/forms/partials/Submit'
import Newsletter from '../components/Newsletter'

const SignUpTemplate = ({ location, pageContext }) => {
  const [activePlan, setActivePlan] = useState(
    _get(location.state, 'plan', 'monthly')
  )

  const { data } = pageContext

  return (
    <Page className={`p-sign-up p-for${data.user}s has-fingerPrints`}>
      <SEO title={`Sign up - ${data.user} ${data.type}`} />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-sm-7 col-md-9">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <span className="c-pageHeader__title">
                        <strong>Sign up</strong>
                      </span>
                    </div>
                    <div className="col-12 col-lg-8">
                      <h1 className="c-pageHeader__intro">
                        <strong>{data.user}</strong> {data.type}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="c-contactSide col-12 d-none col-lg-4 d-lg-block">
                  <div className="c-priceCards">
                    <PriceCard priceCard={data} plan={activePlan} />
                  </div>
                </div>

                <div className="c-contactUsDivider col-12 col-md-1">
                  <span></span>
                </div>

                <div className="col-12 col-lg-7">
                  <Form
                    className="c-contactUsForm"
                    formName={`${data.user} Sign Up Form`}
                    successPage="/sign-up/success"
                  >
                    <p className="mb-3 pb-3">
                      Please complete the form below and a member of our team
                      will be in touch to talk you through the sign up process.
                    </p>

                    <div className="form-group row">
                      <label
                        htmlFor="name"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
                      >
                        Your name*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="email"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
                      >
                        Your email*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="company"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
                      >
                        Company name*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
                        <input
                          type="text"
                          className="form-control"
                          id="company"
                          name="company"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="telephone"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
                      >
                        Phone number*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
                        <input
                          type="tel"
                          className="form-control"
                          id="telephone"
                          name="telephone"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label
                        htmlFor="billing_term"
                        className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
                      >
                        Term*
                      </label>
                      <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
                        <select
                          className="form-control"
                          id="billing_term"
                          name="billing_term"
                          required
                          value={activePlan}
                          onChange={(e) => setActivePlan(e.target.value)}
                        >
                          <option value="monthly">Monthly</option>
                          <option value="annually">Annually</option>
                        </select>
                        <small
                          id="billing_term_help"
                          className="form-text text-muted"
                        >
                          How would you like to be billed?
                        </small>
                      </div>
                    </div>

                    <input type="hidden" name="user" value={data.user} />
                    <input type="hidden" name="type" value={data.type} />

                    <Submit />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default SignUpTemplate
